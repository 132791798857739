/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNonRegisteredCustomer = /* GraphQL */ `
  query CreateNonRegisteredCustomer(
    $nonRegisteredCustomer: NonRegisteredCustomeredInput!
  ) {
    createNonRegisteredCustomer(nonRegisteredCustomer: $nonRegisteredCustomer)
  }
`;
export const listStripeProductPrice = /* GraphQL */ `
  query ListStripeProductPrice {
    listStripeProductPrice
  }
`;
export const stripeGetCustomerSubscription = /* GraphQL */ `
  query StripeGetCustomerSubscription(
    $stripeCustomer: CreateStripeCustomerInput!
  ) {
    stripeGetCustomerSubscription(stripeCustomer: $stripeCustomer)
  }
`;
export const getListing = /* GraphQL */ `
  query GetListing($listing: GetListingInput!) {
    getListing(listing: $listing)
  }
`;
export const getAgent = /* GraphQL */ `
  query GetAgent($agent: GetAgentInput!) {
    getAgent(agent: $agent)
  }
`;
export const sendEmail = /* GraphQL */ `
  query SendEmail($SendEmailInput: SendEmailInput!) {
    sendEmail(SendEmailInput: $SendEmailInput)
  }
`;
export const sendNotificationSms = /* GraphQL */ `
  query SendNotificationSms(
    $SendNotificationSMSInput: SendNotificationSMSInput!
  ) {
    sendNotificationSMS(SendNotificationSMSInput: $SendNotificationSMSInput)
  }
`;
export const schoolByState = /* GraphQL */ `
  query SchoolByState(
    $state: String
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schoolByState(
      state: $state
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stateSchoolId
        ncesSchoolid
        ncesDistrictId
        stateDistrictId
        lowGrade
        highGrade
        schoolName
        district
        countyName
        streetAddress
        city
        state
        zip
        zipFourDigit
        phone
        localeCode
        locale
        charter
        magnet
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listListings = /* GraphQL */ `
  query ListListings(
    $id: ID
    $version: ModelIntKeyConditionInput
    $filter: ModelListingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listListings(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        propertyID
        agentID
        listingPrice
        listingDate
        listingUserEmail
        taxes
        villageTaxes
        originalPrice
        newPrice
        modificationDate
        agentAcceptance
        agentCompensation
        pricePerSqFoot
        seller
        buyer
        listingEndDate
        listingStatus
        createdAt
        updatedAt
        owner
        activeFlag
        soldDate
        version
        agent {
          id
          listingID
          firstName
          lastName
          mobileNumber
          brokerageName
          brokerageAddress
          brokerageCity
          brokerageState
          brokerageZip
          brokeragePhone
          brokerageWebsite
          createdAt
          updatedAt
          owner
          email
          description
          parentOfTheAgent
        }
        property {
          id
          listingID
          addressLine
          unitNo
          city
          state
          postalCode
          section
          block
          lot
          zoning
          waterfrontFrontage
          waterview
          bulkhead
          dockingRight
          beachRight
          adultCommunity
          newConstruction
          schoolDistrictName
          schoolDistrictNumber
          elementarySchool
          elementarySchoolRankStars
          middleSchool
          middleSchoolRankStars
          highSchool
          highSchoolRankStars
          homeType
          noOfStories
          architecturalStyle
          exterior
          roofMaterial
          beds
          fullBath
          halfBath
          squareFeet
          lotDimension
          lotSize
          lotSizeUnit
          yearBuilt
          restructureModelYear
          hoaDues
          hoaSummary
          basementSquareFeet
          garageSquareFeet
          parking
          rooms
          totalRooms
          roomDetails
          basement
          flooring
          indoorFeatures
          coolingSystems
          coolingSystemsZone
          heatingSystems
          heatingFuel
          heatingSystemsZones
          water
          homeAmenities
          outdoorAmenities
          buildingAmenities
          view
          exclusions
          buildingName
          otherInformation
          propertyDescription
          propertyCondition
          petFriendly
          furnished
          Mailbox
          createdAt
          updatedAt
          carGarage
          parkingSpaces
          maintenanceFee
          OtherFeeName
          OtherFeeValue
          sewer
          poolType
          owner
          MLSID
          pets
        }
      }
      nextToken
    }
  }
`;
export const listAgents = /* GraphQL */ `
  query ListAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        listingID
        firstName
        lastName
        mobileNumber
        brokerageName
        brokerageAddress
        brokerageCity
        brokerageState
        brokerageZip
        brokeragePhone
        brokerageWebsite
        createdAt
        updatedAt
        owner
        email
        description
        parentOfTheAgent
        Listing {
          id
          propertyID
          agentID
          listingPrice
          listingDate
          listingUserEmail
          taxes
          villageTaxes
          originalPrice
          newPrice
          modificationDate
          agentAcceptance
          agentCompensation
          pricePerSqFoot
          seller
          buyer
          listingEndDate
          listingStatus
          createdAt
          updatedAt
          owner
          activeFlag
          soldDate
          version
        }
      }
      nextToken
    }
  }
`;
export const agentByBrokerageName = /* GraphQL */ `
  query AgentByBrokerageName(
    $brokerageName: String
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agentByBrokerageName(
      brokerageName: $brokerageName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listingID
        firstName
        lastName
        mobileNumber
        brokerageName
        brokerageAddress
        brokerageCity
        brokerageState
        brokerageZip
        brokeragePhone
        brokerageWebsite
        createdAt
        updatedAt
        owner
        email
        description
        parentOfTheAgent
        Listing {
          id
          propertyID
          agentID
          listingPrice
          listingDate
          listingUserEmail
          taxes
          villageTaxes
          originalPrice
          newPrice
          modificationDate
          agentAcceptance
          agentCompensation
          pricePerSqFoot
          seller
          buyer
          listingEndDate
          listingStatus
          createdAt
          updatedAt
          owner
          activeFlag
          soldDate
          version
        }
      }
      nextToken
    }
  }
`;
export const getProperty = /* GraphQL */ `
  query GetProperty($id: ID!) {
    getProperty(id: $id) {
      id
      listingID
      addressLine
      unitNo
      city
      state
      postalCode
      section
      block
      lot
      zoning
      waterfrontFrontage
      waterview
      bulkhead
      dockingRight
      beachRight
      adultCommunity
      newConstruction
      schoolDistrictName
      schoolDistrictNumber
      elementarySchool
      elementarySchoolRankStars
      middleSchool
      middleSchoolRankStars
      highSchool
      highSchoolRankStars
      homeType
      noOfStories
      architecturalStyle
      exterior
      roofMaterial
      beds
      fullBath
      halfBath
      squareFeet
      lotDimension
      lotSize
      lotSizeUnit
      yearBuilt
      restructureModelYear
      hoaDues
      hoaSummary
      basementSquareFeet
      garageSquareFeet
      parking
      rooms
      totalRooms
      roomDetails
      basement
      flooring
      indoorFeatures
      coolingSystems
      coolingSystemsZone
      heatingSystems
      heatingFuel
      heatingSystemsZones
      water
      homeAmenities
      outdoorAmenities
      buildingAmenities
      view
      exclusions
      buildingName
      otherInformation
      propertyDescription
      propertyCondition
      petFriendly
      furnished
      Mailbox
      createdAt
      updatedAt
      carGarage
      parkingSpaces
      maintenanceFee
      OtherFeeName
      OtherFeeValue
      sewer
      poolType
      owner
      MLSID
      pets
      Listings {
        items {
          id
          propertyID
          agentID
          listingPrice
          listingDate
          listingUserEmail
          taxes
          villageTaxes
          originalPrice
          newPrice
          modificationDate
          agentAcceptance
          agentCompensation
          pricePerSqFoot
          seller
          buyer
          listingEndDate
          listingStatus
          createdAt
          updatedAt
          owner
          activeFlag
          soldDate
          version
        }
        nextToken
      }
    }
  }
`;
export const listPropertys = /* GraphQL */ `
  query ListPropertys(
    $id: ID
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPropertys(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        listingID
        addressLine
        unitNo
        city
        state
        postalCode
        section
        block
        lot
        zoning
        waterfrontFrontage
        waterview
        bulkhead
        dockingRight
        beachRight
        adultCommunity
        newConstruction
        schoolDistrictName
        schoolDistrictNumber
        elementarySchool
        elementarySchoolRankStars
        middleSchool
        middleSchoolRankStars
        highSchool
        highSchoolRankStars
        homeType
        noOfStories
        architecturalStyle
        exterior
        roofMaterial
        beds
        fullBath
        halfBath
        squareFeet
        lotDimension
        lotSize
        lotSizeUnit
        yearBuilt
        restructureModelYear
        hoaDues
        hoaSummary
        basementSquareFeet
        garageSquareFeet
        parking
        rooms
        totalRooms
        roomDetails
        basement
        flooring
        indoorFeatures
        coolingSystems
        coolingSystemsZone
        heatingSystems
        heatingFuel
        heatingSystemsZones
        water
        homeAmenities
        outdoorAmenities
        buildingAmenities
        view
        exclusions
        buildingName
        otherInformation
        propertyDescription
        propertyCondition
        petFriendly
        furnished
        Mailbox
        createdAt
        updatedAt
        carGarage
        parkingSpaces
        maintenanceFee
        OtherFeeName
        OtherFeeValue
        sewer
        poolType
        owner
        MLSID
        pets
        Listings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPropertyByOwner = /* GraphQL */ `
  query GetPropertyByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPropertyByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listingID
        addressLine
        unitNo
        city
        state
        postalCode
        section
        block
        lot
        zoning
        waterfrontFrontage
        waterview
        bulkhead
        dockingRight
        beachRight
        adultCommunity
        newConstruction
        schoolDistrictName
        schoolDistrictNumber
        elementarySchool
        elementarySchoolRankStars
        middleSchool
        middleSchoolRankStars
        highSchool
        highSchoolRankStars
        homeType
        noOfStories
        architecturalStyle
        exterior
        roofMaterial
        beds
        fullBath
        halfBath
        squareFeet
        lotDimension
        lotSize
        lotSizeUnit
        yearBuilt
        restructureModelYear
        hoaDues
        hoaSummary
        basementSquareFeet
        garageSquareFeet
        parking
        rooms
        totalRooms
        roomDetails
        basement
        flooring
        indoorFeatures
        coolingSystems
        coolingSystemsZone
        heatingSystems
        heatingFuel
        heatingSystemsZones
        water
        homeAmenities
        outdoorAmenities
        buildingAmenities
        view
        exclusions
        buildingName
        otherInformation
        propertyDescription
        propertyCondition
        petFriendly
        furnished
        Mailbox
        createdAt
        updatedAt
        carGarage
        parkingSpaces
        maintenanceFee
        OtherFeeName
        OtherFeeValue
        sewer
        poolType
        owner
        MLSID
        pets
        Listings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBidding = /* GraphQL */ `
  query GetBidding($id: ID!) {
    getBidding(id: $id) {
      id
      listingID
      offerPrice
      depositAtContractSigning
      depositAtClosing
      allCashDeal
      offerValidTo
      financingAmount
      inspectionByDate
      appriasalContigency
      appriasalContigencyDays
      financialContigency
      financialContigencyDays
      homeInspectionContigency
      homeInspectionContigencyDays
      homeInsuranceContigency
      homeInsuranceContigencyDays
      rightToAssignContigency
      rightToAssignContigencyDays
      homeSaleContigency
      homeSaleContigencyDays
      titleContigency
      titleContigencyDays
      interestRateCap
      interestRateCapAmount
      loanCommitmentDate
      closingDate
      preApprovalLetter
      buyer
      bidStatus
      seller
      comment
      buyerAgentId
      sellerAgentId
      owner
      readByUser
      createdAt
      updatedAt
      Listings {
        items {
          id
          propertyID
          agentID
          listingPrice
          listingDate
          listingUserEmail
          taxes
          villageTaxes
          originalPrice
          newPrice
          modificationDate
          agentAcceptance
          agentCompensation
          pricePerSqFoot
          seller
          buyer
          listingEndDate
          listingStatus
          createdAt
          updatedAt
          owner
          activeFlag
          soldDate
          version
        }
        nextToken
      }
    }
  }
`;
export const listBiddings = /* GraphQL */ `
  query ListBiddings(
    $id: ID
    $filter: ModelBiddingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBiddings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        listingID
        offerPrice
        depositAtContractSigning
        depositAtClosing
        allCashDeal
        offerValidTo
        financingAmount
        inspectionByDate
        appriasalContigency
        appriasalContigencyDays
        financialContigency
        financialContigencyDays
        homeInspectionContigency
        homeInspectionContigencyDays
        homeInsuranceContigency
        homeInsuranceContigencyDays
        rightToAssignContigency
        rightToAssignContigencyDays
        homeSaleContigency
        homeSaleContigencyDays
        titleContigency
        titleContigencyDays
        interestRateCap
        interestRateCapAmount
        loanCommitmentDate
        closingDate
        preApprovalLetter
        buyer
        bidStatus
        seller
        comment
        buyerAgentId
        sellerAgentId
        owner
        readByUser
        createdAt
        updatedAt
        Listings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBiddingByOwnerBidStatus = /* GraphQL */ `
  query GetBiddingByOwnerBidStatus(
    $owner: String
    $bidStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBiddingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBiddingByOwnerBidStatus(
      owner: $owner
      bidStatus: $bidStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listingID
        offerPrice
        depositAtContractSigning
        depositAtClosing
        allCashDeal
        offerValidTo
        financingAmount
        inspectionByDate
        appriasalContigency
        appriasalContigencyDays
        financialContigency
        financialContigencyDays
        homeInspectionContigency
        homeInspectionContigencyDays
        homeInsuranceContigency
        homeInsuranceContigencyDays
        rightToAssignContigency
        rightToAssignContigencyDays
        homeSaleContigency
        homeSaleContigencyDays
        titleContigency
        titleContigencyDays
        interestRateCap
        interestRateCapAmount
        loanCommitmentDate
        closingDate
        preApprovalLetter
        buyer
        bidStatus
        seller
        comment
        buyerAgentId
        sellerAgentId
        owner
        readByUser
        createdAt
        updatedAt
        Listings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBiddingByBuyerBidStatus = /* GraphQL */ `
  query GetBiddingByBuyerBidStatus(
    $buyer: String
    $bidStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBiddingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBiddingByBuyerBidStatus(
      buyer: $buyer
      bidStatus: $bidStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listingID
        offerPrice
        depositAtContractSigning
        depositAtClosing
        allCashDeal
        offerValidTo
        financingAmount
        inspectionByDate
        appriasalContigency
        appriasalContigencyDays
        financialContigency
        financialContigencyDays
        homeInspectionContigency
        homeInspectionContigencyDays
        homeInsuranceContigency
        homeInsuranceContigencyDays
        rightToAssignContigency
        rightToAssignContigencyDays
        homeSaleContigency
        homeSaleContigencyDays
        titleContigency
        titleContigencyDays
        interestRateCap
        interestRateCapAmount
        loanCommitmentDate
        closingDate
        preApprovalLetter
        buyer
        bidStatus
        seller
        comment
        buyerAgentId
        sellerAgentId
        owner
        readByUser
        createdAt
        updatedAt
        Listings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getZipCode = /* GraphQL */ `
  query GetZipCode($id: ID!) {
    getZipCode(id: $id) {
      id
      zip
      state
      county
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listZipCodes = /* GraphQL */ `
  query ListZipCodes(
    $filter: ModelZipCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZipCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        zip
        state
        county
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getDraft = /* GraphQL */ `
  query GetDraft($id: ID!) {
    getDraft(id: $id) {
      id
      userName
      draftData
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDrafts = /* GraphQL */ `
  query ListDrafts(
    $filter: ModelDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrafts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        draftData
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getScheduleTourOfListing = /* GraphQL */ `
  query GetScheduleTourOfListing($id: ID!) {
    getScheduleTourOfListing(id: $id) {
      id
      listingID
      agentID
      userName
      tourDateTime
      message
      createdAt
      updatedAt
      Listing {
        id
        propertyID
        agentID
        listingPrice
        listingDate
        listingUserEmail
        taxes
        villageTaxes
        originalPrice
        newPrice
        modificationDate
        agentAcceptance
        agentCompensation
        pricePerSqFoot
        seller
        buyer
        listingEndDate
        listingStatus
        createdAt
        updatedAt
        owner
        activeFlag
        soldDate
        version
        agent {
          id
          listingID
          firstName
          lastName
          mobileNumber
          brokerageName
          brokerageAddress
          brokerageCity
          brokerageState
          brokerageZip
          brokeragePhone
          brokerageWebsite
          createdAt
          updatedAt
          owner
          email
          description
          parentOfTheAgent
        }
        property {
          id
          listingID
          addressLine
          unitNo
          city
          state
          postalCode
          section
          block
          lot
          zoning
          waterfrontFrontage
          waterview
          bulkhead
          dockingRight
          beachRight
          adultCommunity
          newConstruction
          schoolDistrictName
          schoolDistrictNumber
          elementarySchool
          elementarySchoolRankStars
          middleSchool
          middleSchoolRankStars
          highSchool
          highSchoolRankStars
          homeType
          noOfStories
          architecturalStyle
          exterior
          roofMaterial
          beds
          fullBath
          halfBath
          squareFeet
          lotDimension
          lotSize
          lotSizeUnit
          yearBuilt
          restructureModelYear
          hoaDues
          hoaSummary
          basementSquareFeet
          garageSquareFeet
          parking
          rooms
          totalRooms
          roomDetails
          basement
          flooring
          indoorFeatures
          coolingSystems
          coolingSystemsZone
          heatingSystems
          heatingFuel
          heatingSystemsZones
          water
          homeAmenities
          outdoorAmenities
          buildingAmenities
          view
          exclusions
          buildingName
          otherInformation
          propertyDescription
          propertyCondition
          petFriendly
          furnished
          Mailbox
          createdAt
          updatedAt
          carGarage
          parkingSpaces
          maintenanceFee
          OtherFeeName
          OtherFeeValue
          sewer
          poolType
          owner
          MLSID
          pets
        }
      }
      agent {
        id
        listingID
        firstName
        lastName
        mobileNumber
        brokerageName
        brokerageAddress
        brokerageCity
        brokerageState
        brokerageZip
        brokeragePhone
        brokerageWebsite
        createdAt
        updatedAt
        owner
        email
        description
        parentOfTheAgent
        Listing {
          id
          propertyID
          agentID
          listingPrice
          listingDate
          listingUserEmail
          taxes
          villageTaxes
          originalPrice
          newPrice
          modificationDate
          agentAcceptance
          agentCompensation
          pricePerSqFoot
          seller
          buyer
          listingEndDate
          listingStatus
          createdAt
          updatedAt
          owner
          activeFlag
          soldDate
          version
        }
      }
      owner
    }
  }
`;
export const listScheduleTourOfListings = /* GraphQL */ `
  query ListScheduleTourOfListings(
    $filter: ModelScheduleTourOfListingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScheduleTourOfListings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listingID
        agentID
        userName
        tourDateTime
        message
        createdAt
        updatedAt
        Listing {
          id
          propertyID
          agentID
          listingPrice
          listingDate
          listingUserEmail
          taxes
          villageTaxes
          originalPrice
          newPrice
          modificationDate
          agentAcceptance
          agentCompensation
          pricePerSqFoot
          seller
          buyer
          listingEndDate
          listingStatus
          createdAt
          updatedAt
          owner
          activeFlag
          soldDate
          version
        }
        agent {
          id
          listingID
          firstName
          lastName
          mobileNumber
          brokerageName
          brokerageAddress
          brokerageCity
          brokerageState
          brokerageZip
          brokeragePhone
          brokerageWebsite
          createdAt
          updatedAt
          owner
          email
          description
          parentOfTheAgent
        }
        owner
      }
      nextToken
    }
  }
`;
export const getMessagesFromUsersToAgent = /* GraphQL */ `
  query GetMessagesFromUsersToAgent($id: ID!) {
    getMessagesFromUsersToAgent(id: $id) {
      id
      listingID
      agentID
      userName
      message
      createdAt
      updatedAt
      tourDateTime
      Listing {
        items {
          id
          propertyID
          agentID
          listingPrice
          listingDate
          listingUserEmail
          taxes
          villageTaxes
          originalPrice
          newPrice
          modificationDate
          agentAcceptance
          agentCompensation
          pricePerSqFoot
          seller
          buyer
          listingEndDate
          listingStatus
          createdAt
          updatedAt
          owner
          activeFlag
          soldDate
          version
        }
        nextToken
      }
      agent {
        id
        listingID
        firstName
        lastName
        mobileNumber
        brokerageName
        brokerageAddress
        brokerageCity
        brokerageState
        brokerageZip
        brokeragePhone
        brokerageWebsite
        createdAt
        updatedAt
        owner
        email
        description
        parentOfTheAgent
        Listing {
          id
          propertyID
          agentID
          listingPrice
          listingDate
          listingUserEmail
          taxes
          villageTaxes
          originalPrice
          newPrice
          modificationDate
          agentAcceptance
          agentCompensation
          pricePerSqFoot
          seller
          buyer
          listingEndDate
          listingStatus
          createdAt
          updatedAt
          owner
          activeFlag
          soldDate
          version
        }
      }
      owner
    }
  }
`;
export const listMessagesFromUsersToAgents = /* GraphQL */ `
  query ListMessagesFromUsersToAgents(
    $filter: ModelMessagesFromUsersToAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagesFromUsersToAgents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listingID
        agentID
        userName
        message
        createdAt
        updatedAt
        tourDateTime
        Listing {
          nextToken
        }
        agent {
          id
          listingID
          firstName
          lastName
          mobileNumber
          brokerageName
          brokerageAddress
          brokerageCity
          brokerageState
          brokerageZip
          brokeragePhone
          brokerageWebsite
          createdAt
          updatedAt
          owner
          email
          description
          parentOfTheAgent
        }
        owner
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      firstName
      lastName
      phone
      role
      email
      arePreApproved
      rentOrOwn
      timeFrame
      mortgageBrokerNeeded
      attorneyNeeded
      agentFirstName
      agentEmail
      agentLastName
      agentPhone
      agentBrokerage
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $id: ID
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        firstName
        lastName
        phone
        role
        email
        arePreApproved
        rentOrOwn
        timeFrame
        mortgageBrokerNeeded
        attorneyNeeded
        agentFirstName
        agentEmail
        agentLastName
        agentPhone
        agentBrokerage
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCustomerByEmail = /* GraphQL */ `
  query GetCustomerByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomerByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        phone
        role
        email
        arePreApproved
        rentOrOwn
        timeFrame
        mortgageBrokerNeeded
        attorneyNeeded
        agentFirstName
        agentEmail
        agentLastName
        agentPhone
        agentBrokerage
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOpenHouse = /* GraphQL */ `
  query GetOpenHouse($id: ID!) {
    getOpenHouse(id: $id) {
      id
      listingID
      openHouseDate
      openHouseStartTime
      openHouseEndTime
      createdAt
      updatedAt
      Listing {
        items {
          id
          propertyID
          agentID
          listingPrice
          listingDate
          listingUserEmail
          taxes
          villageTaxes
          originalPrice
          newPrice
          modificationDate
          agentAcceptance
          agentCompensation
          pricePerSqFoot
          seller
          buyer
          listingEndDate
          listingStatus
          createdAt
          updatedAt
          owner
          activeFlag
          soldDate
          version
        }
        nextToken
      }
      owner
    }
  }
`;
export const listOpenHouses = /* GraphQL */ `
  query ListOpenHouses(
    $filter: ModelOpenHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpenHouses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        listingID
        openHouseDate
        openHouseStartTime
        openHouseEndTime
        createdAt
        updatedAt
        Listing {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getOpenHouseVisitors = /* GraphQL */ `
  query GetOpenHouseVisitors($id: ID!) {
    getOpenHouseVisitors(id: $id) {
      id
      listingID
      customerID
      reasonForVisitingOpenHouse
      agreementDocument
      createdAt
      updatedAt
      Listing {
        items {
          id
          propertyID
          agentID
          listingPrice
          listingDate
          listingUserEmail
          taxes
          villageTaxes
          originalPrice
          newPrice
          modificationDate
          agentAcceptance
          agentCompensation
          pricePerSqFoot
          seller
          buyer
          listingEndDate
          listingStatus
          createdAt
          updatedAt
          owner
          activeFlag
          soldDate
          version
        }
        nextToken
      }
      Customer {
        items {
          id
          firstName
          lastName
          phone
          role
          email
          arePreApproved
          rentOrOwn
          timeFrame
          mortgageBrokerNeeded
          attorneyNeeded
          agentFirstName
          agentEmail
          agentLastName
          agentPhone
          agentBrokerage
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listOpenHouseVisitorss = /* GraphQL */ `
  query ListOpenHouseVisitorss(
    $filter: ModelOpenHouseVisitorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpenHouseVisitorss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listingID
        customerID
        reasonForVisitingOpenHouse
        agreementDocument
        createdAt
        updatedAt
        Listing {
          nextToken
        }
        Customer {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
